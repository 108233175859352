export const environment = {
  production: true,
  BASE_API_URL: "https://api-uat.keptangpos.com/api/",
  THAI_QR_GENERATE:"https://api-uat.keptangpos.com/api/ThaiPayment/qr_payment",
  THAI_QR_PAID_CHECK:"https://api-uat.keptangpos.com/api/ThaiPayment/check_paid",
  GET_REPORT_PATH_URL: "https://api-uat.keptangpos.com/api/Account/get_db_file_paht",
  GET_CREDIT_CARD_TOKEN:"https://api.gbprimepay.com/v2/tokens",
  CONFRIM_CREDIT_CARD_PAID:"https://api.gbprimepay.com/v2/tokens/charge",
  BASE_CREDIT_CARD_URL:"https://api.gbprimepay.com/v2/",
  GRAPHQL_URL: "https://api-uat.keptangpos.com/graphql/",
  GRAPHQL_TOKEN_URL: "https://api-uat.keptangpos.com/api/Account/login",
  GRAPHQL_USERNAME: "admin@localhost.com",
  GRAPHQL_PASSWORD: "P@ssword1",
};
